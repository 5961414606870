@import "https://fonts.googleapis.com/css?family=Montserrat";


.page-general {
  color: #2c2c2c;
  font-size: 14px;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
}

.nav-general {
  padding-bottom: 0.625rem;
  min-height: 53px;
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 15%);
  transition: all 300ms ease 0s;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0,0,0,.125);
}

.navbar-title {
  font-family: "Quicksand", sans-serif !important;
  color: #40B6C3 !important;
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  white-space: nowrap;
}

.general-header {
  background-color: #f5f8fa;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0,0,0,.125);
  vertical-align: middle;
}


.headshot-container {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 0px 10px 25px 0px rgb(0 0 0 / 30%);
}


.github-button {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 20px;
  background-color: #1C1C1E;
  display: inline-block;
  color: #eff3f6;
  border-radius: 0.25em;
  border-color: rgba(27, 31, 35, 0.2);
  padding: 3px 10px;
}

.github-icon {
  display: inline-block;vertical-align: text-bottom;fill: currentColor;
}

.content-general {
  padding: 70px 0;
  position: relative;
  background: #FFFFFF;
}


.content-title {
  font-weight: 700;
  padding-top: 30px;
  font-size: 1.825em;
  margin-bottom: 30px;
  line-height: 1.4em;
}

.nav-link {
  color: #003136;
}

.footer-general {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  line-height: 60px;
}

a {
  color: #40B6C3;
  text-decoration: none;
}